<template>
  <div>
    <Header :headerIndex="2"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner2.png" alt="" />
      <div class="detail_wrap">
        <div class="product_inner max_1440">
          <div class="flex-box">
            <router-link to="/productcenter" class="g_breadcrumb">Product Center</router-link>
            <div class="g_breadcrumb mlr10">></div>
            <div class="g_breadcrumb">Product Center Details</div>
          </div>

          <div class="title">{{product.name}}</div>
          <div class="fuwenben" style="color:black;" v-html="product.detail">
          </div>
          <!-- <img class="img_detail" :src="product.detail_image" alt="" /> -->
          <!-- <div v-html="product.detail"></div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import api from '../api';
export default {
  components: {
  },
  data(){
   return{
      product:[],
      productid:''
   }
  },
  created(){
    this.productid = this.$route.query.productid
    // console.log(this.productid);
    this.getproduct()
  },
  methods:{
    getproduct(){
      var id = this.productid?this.productid:''
      api.doPost('/api/product/productdetail',{id:id},(res)=>{
          // console.log(res)
          this.product = res
      })
    }
  }
};
</script>
